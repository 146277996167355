<style lang="scss" scoped>
.section {
    color: rgb(51, 51, 51);
    font-size: 14px;
    height: 100%;
    font-weight: 400;

    & > div, & > p {
        display: flex;
        margin-bottom: 13px;

        p + p {
            font-size: 13px;
            color: #909399;
            font-weight: normal;
        }

        label {
            display: block;
            color: #999;
            width: 75px;
            font-size: 13px;
            font-weight: normal;
        }
    }
}

.em {
    font-size: 14px;
    font-weight: 500;
}

/deep/ {
    .el-table__cell {
        vertical-align: top
    }

    .list-search-after {
        width: 330px;
        text-align: right;
    }
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <comListSearch>
                <div slot="list-search-before">
                    <el-row>
                        <el-col :span="4">
                            <SelectCity ref="selectCityRef" :isBorder="false" size="medium" clearable
                                        @update:city="handleCityChange"/>
                        </el-col>
                        <el-col :span="6" :offset="1">
                            <EtSelect placeholder="订单来源" v-model="form.sourceType" :options="getSourceTypeList"/>
                        </el-col>
                        <el-col :span="8" :offset="1">
                            <el-date-picker v-model="date" type="daterange" range-separator="至"
                                            start-placeholder="出行开始日期"
                                            end-placeholder="出行结束日期">
                            </el-date-picker>
                        </el-col>
                    </el-row>
                </div>
                <div slot="list-search-after">
                    <el-button @click="reset" :loading="loading">重置</el-button>
                    <el-button type="primary" @click="getData(1)" :loading="loading">搜索</el-button>
                    <el-button type="primary" plain @click="handleExport" :loading="loading">导出</el-button>
                </div>
            </comListSearch>
            <div class="eTrip-section-cont quote-list">
                <el-table :data="result.data" style="width: 100%" :show-header="false" v-loading="loading">
                    <el-table-column width="250">
                        <template slot-scope="scope">
                            <div class="section">
                                <p><label>订单编号:</label><span>{{ scope.row.orderNo }}</span></p>
                                <p><label>创建时间:</label><span>{{ scope.row.createTime | dateCus }}</span></p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <template slot-scope="scope">
                            <div class="section">
                                <p><label>用车时间:</label><span>{{ scope.row.reserveTime | dateCus }}</span></p>
                                <p><label>城市名称:</label><span>{{ scope.row.cityName }}</span></p>
                                <div>
                                    <label>开始地址:</label>
                                    <div>
                                        <p>{{ scope.row.departure.title }}</p>
                                        <p>{{ scope.row.departure.address }}</p>
                                    </div>
                                </div>
                                <div>
                                    <label>结束地址:</label>
                                    <div>
                                        <p>{{ scope.row.destination.title }}</p>
                                        <p>{{ scope.row.destination.address }}</p>
                                    </div>
                                </div>
                                <p><label>用户姓名:</label><span>{{ scope.row.tripUserName }}</span></p>
                                <p><label>用户手机号:</label><span>{{ scope.row.tripUserMobile }}</span></p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <template slot-scope="scope">
                            <div class="section">
                                <p><label>类型:</label><span>旅游用车</span></p>
                                <p><label>产品名称:</label><span>{{ scope.row.productName }}</span></p>
                                <p><label>车型:</label><span> {{ formatCarModel(scope.row.vehicleType).name }}</span>
                                </p>
                                <p><label>司机姓名:</label><span>{{ scope.row.driverName }}</span></p>
                                <p><label>司机手机号:</label><span>{{ scope.row.driverMobile }}</span></p>
                                <p><label>车牌号:</label><span>{{ scope.row.carNum }}</span></p>
                                <p><label>供应商名称:</label><span>{{ scope.row.supplierName }}</span></p>
                                <p><label> 运营商名称:</label><span>{{ scope.row.operatorName }}</span></p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="200">
                        <template slot-scope="scope">
                            <div class="section">
                                <p>
                                    <label>订单金额:</label>
                                    <span>{{ scope.row.orderAmount | currency(1,'¥') }}</span>
                                </p>
                                <p>
                                    <label>结算金额:</label>
                                    <span>{{ scope.row.settleAmount | currency(1,'¥') }}</span>
                                </p>
                                <p>
                                    <label>应收金额:</label>
                                    <span>
                                        {{
                                            (scope.row.orderAmount - scope.row.channelAmount) | currency(1,'¥')
                                        }}
                                    </span>
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div style="display: flex;justify-content: flex-end;margin: 10px 0;width: 100%">
                <el-pagination background layout="prev, pager, next" :total="result.total"
                               @current-change="getData"></el-pagination>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
import {bmsExportIntegrateTripOrderApi, getIntegrateTripOrderApi} from '@/www/urls/orderUrls'
import comListSearch from '@/www/components/comListSearch/index.vue'
import SelectCity from '@/components/selectCity';
import EtSelect from "@/components/et-select";
import {getSeatModel} from "@/data";
import dayjs from "dayjs";

const sourceTypeList = [
    {
        label: "携程",
        value: 12
    },
    {
        label: "飞猪",
        value: 15
    },
    {
        label: "同程",
        value: 19
    },
]
export default {
    name: 'TouristCarOrder',
    components: {
        comListSearch,
        SelectCity,
        EtSelect
    },
    data() {
        return {
            loading: false,
            result: {
                data: [],
                total: 0
            },
            date: [],
            form: {
                pageIndex: 1,
                pageSize: 10,
                cityName: undefined,
            }
        }
    },
    computed: {
        getSourceTypeList() {
            return sourceTypeList
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(pageIndex = 1) {
            this.form.pageIndex = pageIndex;
            const form = {
                ...this.form
            }
            !form.sourceType && delete form.sourceType
            !form.cityName && delete form.cityName
            if (this.date && this.date.length === 2) {
                form.tripStartTime = +new Date(this.date[0])
                form.tripEndTime = +new Date(this.date[1])
            }
            this.loading = true
            const ret = await getIntegrateTripOrderApi(form);
            this.loading = false
            if (ret.success)
                this.result = {
                    ...ret.data,
                    data: ret.data.data.map((item) => ({
                        ...item,
                        departure: JSON.parse(item.departure),
                        destination: JSON.parse(item.destination),
                    }))
                }
        },
        async handleExport() {
            const form = {
                ...this.form,
                pageIndex: 1
            }
            !form.sourceType && delete form.sourceType
            !form.cityName && delete form.cityName
            if (this.date && this.date.length === 2) {
                form.tripStartTime = +new Date(this.date[0])
                form.tripEndTime = +new Date(this.date[1])
            }
            this.loading = true
            const ret = await bmsExportIntegrateTripOrderApi(form);
            this.loading = false
            const a = document.createElement('a');
            a.download = '旅游用车订单列表' + dayjs().format('YYYY-MM-DD') + '.xls';
            a.href = window.URL.createObjectURL(ret);
            a.click();

        },
        handleCityChange({city}) {
            this.form.cityName = city
        },
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
            }
            return info;
        },
        reset() {
            this.form.cityName = null
            this.form.sourceType = ""
            this.date = []
            this.$refs['selectCityRef'].clear();
            this.getData();
        }
    }
}
</script>
